import { Button, Card, Col, Flex, Row } from "antd";
import React from "react";

import { CalendarOutlined } from "@ant-design/icons";
import { getYearsArray } from "@/utils";

function PublicHolidays(props) {
  const { loading, list, year, yearChange } = props;
  const yearList = getYearsArray();

  const isPassed = (item) => {
    return new Date(item.date).getTime() <= new Date().getTime();
  };

  return (
    <Card loading={loading}>
      <h1 className="title">
        <CalendarOutlined /> Public holidays
      </h1>
      <Row className="tb-hd">
        <Col span={4}>Date</Col>
        <Col className="pc-col" span={10}>Local name</Col>
        <Col span={10}>Name</Col>
      </Row>
      {list.map((item, index) => {
        return (
          <Row key={index}>
            <Col span={24}>
              <Row className="tb-title">
                <Col span={4}>{item[0]}</Col>
                <Col className="pc-col" span={10}></Col>
                <Col span={10}></Col>
              </Row>
              {item[1].map((d, i) => {
                return (
                  <Row
                    key={i}
                    className={`tb-bd ${isPassed(d) ? "tb-passed" : ""}`}
                  >
                    <Col span={4}>{d.date}</Col>
                    <Col className="pc-col" span={10}>{d.localName}</Col>
                    <Col span={10}>{d.name}</Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        );
      })}
      <Flex gap={5} className="pages">
        {yearList.map((item) => {
          return (
            <Button
              onClick={() => {
                yearChange(item);
              }}
              type={item === Number(year) ? "primary" : "none"}
              key={item}
            >
              {item}
            </Button>
          );
        })}
      </Flex>
    </Card>
  );
}

export default PublicHolidays;
