import React, { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import "../Home/styles.scss";
import { Col, Flex, Row, Breadcrumb, Card } from "antd";
import BorderCountry from "../Home/components/BorderCountry";
import PublicHolidays from "./components/PublicHolidays";
import { getCountryInfo, getHolidaysByCountryAndYear } from "@/api";
import { groupByMonth } from "@/utils";
// import PaymentList from "../components/PaymentsList/PaymentList";

function Detail() {
  const { id, year } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const [detail, setDetail] = useState({});

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const yearChange = (item) => {
    history.push(`/detail/${id}/${item}`);
  };

  useEffect(() => {
    getCountryInfo(id)
      .then((res) => {
        console.log("getCountryInfo", res);
        setDetail(res);
      })
      .catch((e) => {
        console.log("error", e);
      })
      .then(() => {
        console.log("finally");
      });
  }, [id]);

  useEffect(() => {
    getHolidaysByCountryAndYear(year, id)
      .then((res) => {
        console.log(
          "getHolidaysByCountryAndYear",
          Object.entries(groupByMonth(res))
        );
        const objectList = Object.entries(groupByMonth(res));
        setList(objectList);
      })
      .catch((e) => {
        console.log("error", e);
        setList([]);
      })
      .then(() => {
        console.log("finally");
        setLoading(false);
      });
  }, [id, year]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Breadcrumb
        style={{ padding: "5px 10px", marginTop: 15 }}
        items={[
          {
            title: <a href="/">Home</a>,
          },
          {
            title: "Detail",
          },
        ]}
      />
      <Flex className="country-info">
        <img
          src={require(`@/assets/flags/${id.toLowerCase()}.png`)}
          alt=""
          className="flags"
        />
        <div className="info">
          <div className="name">
            Public Holidays in {detail.commonName} <strong>{year}</strong>
          </div>
          <div className="sub">
            Discover the National Holidays for {detail.commonName} and plan your
            long weekends with our guide. Find all the information you need in
            one place.
          </div>
        </div>
      </Flex>
      {/* <PaymentList /> */}
      {list.length || loading ? (
        <Row className="page-container">
          <Col xs={24} sm={24} md={14} lg={14} xl={14} className="box">
            <PublicHolidays
              loading={loading}
              list={list}
              year={year}
              yearChange={yearChange}
            />
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10} className="box">
            <BorderCountry
              title="Border countries"
              list={detail}
              code={id}
            />
          </Col>
        </Row>
      ) : (
        <Row className="error-box">
          <Col span={24}>
            <Card title="Sorry">
              No public holidays for {detail.commonName} available.
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

export default Detail;
