import React from "react";
import { Table } from "antd";
function CTable(props) {
  const paginationProps = props.hidePagination
    ? false
    : {
        ...{
          showSizeChanger: true,
          // hideOnSinglePage: true,
          showQuickJumper: true,
          defaultPageSize: 20,
          showTotal: (total) => `共 ${total} 条`,
        },
        ...props.pagination,
      };
  return (
    <div className="tb">
      <Table
        {...props}
        loading={props.loading}
        pagination={paginationProps}
        columns={props.columns}
        dataSource={props.dataSource}
      />
    </div>
  );
}

export default CTable;
