import React from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

function Agreement() {
  return (
    <div className="about">
      <h1>FreeDays User Agreement</h1>
      <h3>Last Updated: 2024-09-25</h3>
      <p>
        Welcome to FreeDays! This User Agreement ("Agreement") governs your use
        of our website ("Site") and services. By accessing or using FreeDays,
        you agree to comply with and be bound by this Agreement. If you do not
        agree to these terms, please do not use our services.
      </p>
      <h1>1. Acceptance of Terms</h1>
      <p>
        By accessing FreeDays, you acknowledge that you have read, understood,
        and agree to be bound by this Agreement, as well as our Privacy Policy.
      </p>
      <h1>2. Changes to the Agreement</h1>
      <p>
        We reserve the right to modify this Agreement at any time. Any changes
        will be effective immediately upon posting. Your continued use of the
        Site after changes are posted constitutes your acceptance of the new
        terms.
      </p>
      <h1>3. Use of the Site</h1>
      <p>You must be at least 13 years old to use FreeDays.</p>
      <p>
        Global Reach: No matter where you are, FreeDays covers holidays in your
        region and beyond.
      </p>
      <p>
        You agree to use the Site only for lawful purposes and in a manner that
        does not infringe on the rights of others or restrict their use of the
        Site.
      </p>
      <p>
        You may not attempt to gain unauthorized access to any part of the Site,
        other accounts, or systems connected to the Site.
      </p>
      <h1>4. Content and Services</h1>
      <p>
        FreeDays provides information on global holidays, including public,
        religious, and special observances.
      </p>
      <p>
        All content is provided “as is” without any guarantees of accuracy or
        availability. We strive to keep information current but cannot guarantee
        the accuracy of holiday data.
      </p>
      <h1>5. Intellectual Property</h1>
      <p>
        All content, trademarks, logos, and data on FreeDays are the property of
        FreeDays or its licensors.
      </p>
      <p>
        You may not copy, distribute, modify, or create derivative works from
        any content on the Site without prior written consent.
      </p>
      <h1>6. Privacy Policy</h1>
      <p>
        Your privacy is important to us. Please review our{" "}
        <NavLink to={"/privacy"}>Privacy Policy</NavLink> to understand how we
        collect, use, and protect your information.
      </p>
      <h1>7. Limitation of Liability</h1>
      <p>
        FreeDays shall not be liable for any damages arising from your use of
        the Site or any information obtained from it.
      </p>
      <p>
        The Site may contain links to third-party websites. FreeDays is not
        responsible for the content, privacy practices, or accuracy of these
        external sites.
      </p>
      <h1>8. Indemnification</h1>
      <p>
        You agree to indemnify and hold FreeDays, its affiliates, and its
        employees harmless from any claims, losses, liabilities, damages, costs,
        or expenses arising out of your use of the Site or violation of this
        Agreement.
      </p>
      <h1>9. Termination</h1>
      <p>
        We reserve the right to suspend or terminate your access to FreeDays at
        any time, without notice, for conduct that violates this Agreement or is
        harmful to other users.
      </p>
      <h1>10. Governing Law</h1>
      <p>
        This Agreement shall be governed by and construed in accordance with the
        laws of [Your Country/State], without regard to its conflict of law
        principles.
      </p>
      <h1>11. Contact Us</h1>
      <p>
        If you have any questions about this Agreement, please contact us at{" "}
        <a href="mailto: shallyzheng0929@gmail.com">
          shallyzheng0929@gmail.com
        </a>
        .
      </p>
    </div>
  );
}

export default Agreement;
