import React, { useEffect, useState } from "react";
import logo from "@/assets/logo.png";
import "./styles.scss";
import { Drawer, Flex, Select } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useHistory, useLocation, NavLink } from "react-router-dom";
import AllStores from "@/stores/stores";
import Nav from "./Nav";

function Header() {
  const history = useHistory();
  const location = useLocation();
  const { countryList } = AllStores();
  const [value, setValue] = useState(undefined);
  const [visible, setVisible] = useState(false);

  const toggleMenu = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onChange = (v) => {
    console.log(`selected ${v}`);
    setValue(v);
    if (!v) {
      return;
    }
    history.push(`/detail/${v}/${new Date().getFullYear()}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  useEffect(() => {
    // console.log(location.pathname);
    setValue(undefined);
  }, [location.pathname]);

  return (
    <div className="header-wrap">
      <Flex className="header">
        <NavLink to="/" className="logo-info">
          <img src={logo} className="logo" alt="logo" />
          <div className="name">{process.env.REACT_APP_BASE_NAME}</div>
        </NavLink>

        <Nav />
        <MenuOutlined onClick={toggleMenu} className="mb-menu" />
      </Flex>
      <div className="search-box">
        <Select
          showSearch
          allowClear
          value={value}
          placeholder="Search"
          optionFilterProp="label"
          onChange={onChange}
          onSearch={onSearch}
          options={countryList}
        />
      </div>
      <Drawer
        title={
          <NavLink to="/" className="logo-info">
            <img src={logo} className="logo" alt="logo" />
            <div className="name">{process.env.REACT_APP_BASE_NAME}</div>
          </NavLink>
        }
        getContainer={document.querySelector(".header")}
        placement={"left"}
        width={200}
        closable={false}
        onClose={onClose}
        open={visible}
        key={"left"}
      >
        <Nav
          type={"mobile"}
          menuChoose={() => {
            onClose();
          }}
        />
      </Drawer>
    </div>
  );
}

export default Header;
