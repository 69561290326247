import { Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getHolidaysByCountry } from "@/api";
import { DeliveredProcedureOutlined } from "@ant-design/icons";
import CTable from "@/pages/components/CTable";
import { getCountryCodeFromLanguage } from "@/utils";

function CountryHoliday() {
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => (
        <>
          <div>{record.localName}</div>
          <div>{record.name}</div>
        </>
      ),
    },
  ];

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const countryCode = getCountryCodeFromLanguage();
    getHolidaysByCountry(countryCode)
      .then((res) => {
        console.log("getHolidaysByCountry", res);
        setList(res);
      })
      .catch((e) => {
        console.log("error", e);
      })
      .then(() => {
        console.log("finally");
        setLoading(false);
      });
  }, []);
  return (
    <Card loading={loading}>
      <Row>
        <Col>
          <h1 className="title">
            <DeliveredProcedureOutlined /> Next Public Holidays in China
          </h1>
          <CTable
            size="middle"
            columns={columns}
            dataSource={list}
            loading={loading}
            sticky={true}
            hidePagination={true}
            rowKey={(record) => record.countryCode + record.date}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default CountryHoliday;
