export function getCountryName(list, code) {
  return list.find((item) => item.countryCode === code);
}

export function getCountryCode(list, name) {
  return list.find((item) => item.name === decodeURIComponent(name));
}

//按日期分组
export function groupByMonth(data) {
  return data.reduce((acc, item) => {
    const [year, month] = item.date.split("-").slice(0, 2); // 提取年份和月份
    const key = `${year}-${month}`; // 创建键，格式为'YYYY-MM'

    if (!acc[key]) {
      acc[key] = []; // 如果该键不存在，则初始化一个空数组
    }

    acc[key].push(item); // 将当前项推入对应键的数组中

    return acc;
  }, {});
}

//当前年份以及其前后各5年的年份数组
export function getYearsArray() {
  const currentYear = new Date().getFullYear(); // 获取当前年份
  const years = [];

  // 从当前年份开始，向前和向后各推5年
  for (let i = -5; i <= 5; i++) {
    years.push(currentYear + i); // 将计算出的年份添加到数组中
  }

  return years;
}

//根据ua获取国家码
export function getCountryCodeFromLanguage() {
  const language = navigator.language || navigator.userLanguage; // 获取浏览器语言
  const countryCode = language.split('-')[1] || language.split('_')[1]; // 提取国家码
  return countryCode ? countryCode.toUpperCase() : null;
}