//api列出的所有可用国家
export async function getAvailableCountries() {
  const url = "https://date.nager.at/api/v3/AvailableCountries";
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

//获取指定国家附近国家的信息
export async function getCountryInfo(code) {
  const url = "https://date.nager.at/api/v3/CountryInfo/" + code;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

//指定国家未来365天的假期信息
export async function getHolidaysByCountry(code) {
  const url = "https://date.nager.at/api/v3/NextPublicHolidays/" + code;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

//指定国家和年份假期信息
export async function getHolidaysByCountryAndYear(year, code) {
  const url =
    "https://date.nager.at/api/v3/PublicHolidays/" + year + "/" + code;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

//全球未来7天假日
export async function getNext7Days() {
  const url = "https://date.nager.at/api/v3/NextPublicHolidaysWorldwide";
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}
