import React from "react";

function About() {
  return (
    <div className="about">
      <h1>About Us</h1>
      <p>
        Welcome to FreeDays, your ultimate guide to discovering holidays and
        special dates from around the globe. Whether you're planning a trip,
        curious about cultural celebrations, or just looking to know when the
        next day off is, FreeDays provides comprehensive, accurate, and
        up-to-date holiday information for countries worldwide.
      </p>
      <h1>Our Mission</h1>
      <p>
        Our mission is simple: to connect people with the world's diverse
        traditions and celebrations. We believe that understanding global
        holidays enriches our lives, fosters cultural appreciation, and helps
        people plan their personal and professional time more effectively.
      </p>
      <h1>What We Offer</h1>
      <p>
        Global Holiday Data: From national public holidays to religious
        observances and unique local festivals, FreeDays covers a wide range of
        dates from over 200 countries and regions.
      </p>
      <p>
        Easy Planning: Use FreeDays to plan vacations, schedule business
        meetings, or simply keep track of upcoming days off.
      </p>
      <p>
        User-Friendly Interface: Our platform is designed to be intuitive,
        making it easy to find the holiday information you need quickly and
        efficiently.
      </p>
      <h1>Why Choose FreeDays?</h1>
      <p>
        Accurate Information: We strive to keep our holiday data up-to-date and
        reliable.
      </p>
      <p>
        Global Reach: No matter where you are, FreeDays covers holidays in your
        region and beyond.
      </p>
      <p>
        Free Access: We are committed to providing free access to essential
        holiday information for everyone.
      </p>
      <h1>Join Us on Our Journey</h1>
      <p>
        We are continuously expanding our database and improving our user
        experience. Your feedback is valuable to us, and we welcome any
        suggestions you may have to help make FreeDays better.
      </p>
      <p>
        Thank you for visiting FreeDays. Let’s make every day count, one holiday
        at a time!
      </p>
    </div>
  );
}

export default About;
