import React from "react";
import "./styles.scss";
import { Col, Row } from "antd";
import WorldHolidays from "./components/WorldHolidays";
import BorderCountry from "./components/BorderCountry";
import CountryHoliday from "./components/CountryHoliday";
import Clock from "./components/Clock";

function Home() {
  return (
    <>
      <Row className="page-container">
        <Col span={24} className="box">
          <Clock />
        </Col>
      </Row>
      <Row className="page-container">
        <Col xs={24} sm={24} md={16} lg={16} xl={16} className="box">
          <WorldHolidays />
          <BorderCountry />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="box box2">
          <CountryHoliday />
        </Col>
      </Row>
    </>
  );
}

export default Home;
