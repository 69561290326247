import { create } from "zustand";

const allStores = create((set) => ({
  countryList: [],
  updateCountryList: (list) => {
    set({ countryList: list });
  },
}));

export default allStores;
