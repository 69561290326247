import { Menu } from "antd";
import { GlobalOutlined, TeamOutlined, HomeOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

function Nav(props) {
  const { type, menuChoose } = props;
  const items = [
    {
      label: "Home",
      key: "/",
      icon: <HomeOutlined />,
    },
    {
      label: "Countries",
      key: "/countries",
      icon: <GlobalOutlined />,
    },
    {
      label: "About",
      key: "/about",
      icon: <TeamOutlined />,
    },
  ];

  const history = useHistory();
  const location = useLocation();

  const [current, setCurrent] = useState("/");
  const onClick = (e) => {
    setCurrent(e.key);
    if (e.key === current) {
      return;
    }
    history.push({
      pathname: e.key,
    });
    if (type) {
      menuChoose();
    }
  };

  useEffect(() => {
    // console.log(location.pathname);
    setCurrent(location.pathname);
  }, [location.pathname]);

  return (
    <Menu
      className={`menu ${type ? "menu-side" : ""}`}
      onClick={onClick}
      selectedKeys={[current]}
      mode={type ? "vertical" : "horizontal"}
      items={items}
    />
  );
}

export default Nav;
