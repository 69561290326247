import React from "react";

function Privacy() {
  return (
    <div className="about">
      <h1>FreeDays Privacy Policy</h1>
      <h3>Last Updated: 2024-09-25</h3>
      <p>
        At FreeDays, we are committed to protecting your privacy. This Privacy
        Policy explains how we collect, use, disclose, and safeguard your
        information when you visit our website, www.freedays.xyz(“Site”). Please
        read this policy carefully. If you do not agree with the terms of this
        policy, please do not access the Site.
      </p>
      <h1>1. Information We Collect</h1>
      <p>
        Personal Data: We do not collect personal data unless you voluntarily
        provide it, such as when subscribing to a newsletter, contacting us, or
        participating in surveys.
      </p>
      <p>
        Non-Personal Data: We may collect non-personal information such as
        browser type, IP address, access times, and the pages you visit on our
        Site.
      </p>
      <p>
        Cookies and Tracking: We use cookies and similar tracking technologies
        to enhance your browsing experience, analyze site traffic, and
        personalize content.
      </p>
      <h1>2. How We Use Your Information</h1>
      <p>We may use information collected about you in the following ways: </p>
      <p>To operate and maintain our website.</p>
      <p>To improve our services, website content, and user experience.</p>
      <p>To send periodic emails, if you have opted-in to receive them.</p>
      <p>To respond to your comments, questions, or requests.</p>
      <h1>3. Disclosure of Your Information</h1>
      <p>
        We do not sell, trade, or rent your personal information to third
        parties. We may share your information in the following circumstances:
      </p>
      <p>
        Service Providers: We may share data with trusted service providers who
        assist us in operating our Site.
      </p>
      <p>
        Legal Requirements: We may disclose information if required to do so by
        law or in response to valid requests by public authorities.
      </p>
      <h1>4. Data Security</h1>
      <p>
        We take reasonable steps to protect your information from unauthorized
        access, use, alteration, or destruction. However, please be aware that
        no method of data transmission over the internet or electronic storage
        is 100% secure.
      </p>

      <h1>5. Your Rights</h1>
      <p>Depending on your location, you may have the right to:</p>
      <p>Access and request a copy of your data.</p>
      <p>Request correction or deletion of your data.</p>
      <p>Opt-out of receiving marketing communications.</p>
      <p>Lodge a complaint with a supervisory authority.</p>

      <h1>6. Third-Party Websites</h1>
      <p>
        Our Site may contain links to other websites. We are not responsible for
        the privacy practices of those websites. We encourage you to read their
        privacy policies.
      </p>
      <h1>7. Children’s Privacy</h1>
      <p>
        FreeDays does not knowingly collect personal information from children
        under the age of 13. If we learn that we have collected personal
        information from a child under 13 without parental consent, we will
        delete that information promptly.
      </p>
      <h1>8. Changes to This Privacy Policy</h1>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page with an updated “Last Updated” date. We encourage
        you to review this policy periodically.
      </p>
      <h1>9. Contact Us</h1>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at <a href="mailto: shallyzheng0929@gmail.com">shallyzheng0929@gmail.com</a>.
      </p>
    </div>
  );
}

export default Privacy;
