import { Card } from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);

const timeZoneList = [
  { name: "Beijing", zone: "Asia/Shanghai" },
  { name: "Paris", zone: "Europe/Paris" },
  { name: "London", zone: "Europe/London" },
  { name: "New York", zone: "America/New_York" },
  { name: "Los Angeles", zone: "America/Los_Angeles" },
  { name: "Tokyo", zone: "Asia/Tokyo" },
];

let timer = null;
function Clock() {
  const [timeZone, setTimeZone] = useState("Asia/Shanghai");
  const [time, setTime] = useState();
  const [date, setDate] = useState();
  const [week, setWeek] = useState(0);

  const init = (zone) => {
    const dateStr = dayjs()
      .weekday(dayjs().tz(zone).day())
      .format("YYYY-MM-DD dddd");
    const weekStr = dayjs(dayjs().tz(zone).format("YYYY-MM-DD")).week();
    console.log(dateStr, weekStr);
    setTimeZone(zone);
    setDate(dateStr);
    setWeek(weekStr);
    setTime(dayjs().tz(zone).format("HH:mm:ss"));
    timer = setInterval(() => {
      setTime(dayjs().tz(zone).format("HH:mm:ss"));
    }, 1000);
  };

  const onChange = (item) => {
    window.clearInterval(timer);
    timer = null;
    init(item.zone);
  };

  const formatZone = (item) => {
    return dayjs().tz(item.zone).format("HH:mm");
  };

  useEffect(() => {
    const currentZone = dayjs.tz.guess();
    init(currentZone);
  }, []);
  return (
    <Card className="clock-box">
      <div className="title">Current Time Zone is {timeZone}</div>
      <div className="sub">
        {date}, Week {week}
      </div>
      <div className="con">{time}</div>
      <div className="list">
        {timeZoneList.map((item, index) => {
          return (
            <div
              key={index}
              className={`list-item ${
                timeZone === item.zone ? "selected" : ""
              }`}
              onClick={() => {
                onChange(item);
              }}
            >
              <div className="name">{item.name}</div>
              <div>{formatZone(item)}</div>
            </div>
          );
        })}
      </div>
    </Card>
  );
}

export default Clock;
