import { Flex } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div>Copyright © 2024 All Rights Reserved</div>
      <Flex gap={10} align="center" justify="center">
        <NavLink to="/agreement">User Agreement</NavLink> | 
        <NavLink to="/privacy">Privacy Policy</NavLink>
      </Flex>
    </div>
  );
}

export default Footer;
