import { Card, Empty } from "antd";
import React, { useEffect, useState } from "react";
import { OrderedListOutlined } from "@ant-design/icons";
import { getCountryInfo } from "@/api";
import { useHistory } from "react-router-dom";
import { getCountryCodeFromLanguage } from "@/utils";

function BorderCountry(props) {
  const { list } = props;
  console.log(props);
  const history = useHistory();
  const [bordersInfo, setBordersInfo] = useState({});
  const [loading, setLoading] = useState(true);

  const goDetail = (item) => {
    history.push(`/detail/${item.countryCode}/${new Date().getFullYear()}`);
  };
  useEffect(() => {
    if (list) {
      setBordersInfo(list);
      setTimeout(() => {
        setLoading(false);
      }, 300);
    } else {
      const countryCode = getCountryCodeFromLanguage();
      const code = props.code || countryCode;
      getCountryInfo(code)
        .then((res) => {
          console.log("getCountryInfo", res);
          setBordersInfo(res);
        })
        .catch((e) => {
          console.log("error", e);
        })
        .then(() => {
          console.log("finally");
          setLoading(false);
        });
    }
  }, [list, props.code]);
  return (
    <Card loading={loading}>
      <h1 className="title">
        <OrderedListOutlined />{" "}
        {props.title || "Interesting countries for their region"}
      </h1>
      {bordersInfo.borders && bordersInfo.borders.length ? (
        <div className="country-list">
          <div
            className="columns"
            onClick={() => {
              goDetail(bordersInfo);
            }}
          >
            <img
              src={require(`@/assets/flags/${bordersInfo.countryCode.toLowerCase()}.png`)}
              alt={bordersInfo.commonName}
              className="flags"
            />
            <div>{bordersInfo.commonName}</div>
          </div>
          {bordersInfo.borders?.map((item, index) => {
            return (
              <div
                key={index}
                className="columns"
                onClick={() => {
                  goDetail(item);
                }}
              >
                <img
                  src={require(`@/assets/flags/${item.countryCode.toLowerCase()}.png`)}
                  alt={item.commonName}
                  className="flags"
                />
                <div>{item.commonName}</div>
              </div>
            );
          })}
        </div>
      ) : (
        <Empty description="Data Not Found" />
      )}
    </Card>
  );
}

export default BorderCountry;
