import { Button, Flex, List } from "antd";
import React, { useEffect } from "react";
import AllStores from "@/stores/stores";

function Countries(props) {
  const { countryList } = AllStores();
  const goDetail = (item) => {
    props.history.push(
      `/detail/${item.countryCode}/${new Date().getFullYear()}`
    );
  };
  useEffect(() => {
    // eslint-disable-next-line no-undef
    new jsVectorMap({
      zoomButtons: false,
      showTooltip: true,
      zoomOnScroll: false,
      series: {
        regions: [
          {
            attribute: "fill",
            scale: {
              Supported: "#08d191",
            },
            values: {
              AD: "Supported",
              AL: "Supported",
              AM: "Supported",
              AR: "Supported",
              AT: "Supported",
              AU: "Supported",
              AX: "Supported",
              BA: "Supported",
              BB: "Supported",
              BE: "Supported",
              BG: "Supported",
              BJ: "Supported",
              BO: "Supported",
              BR: "Supported",
              BS: "Supported",
              BW: "Supported",
              BY: "Supported",
              BZ: "Supported",
              CA: "Supported",
              CH: "Supported",
              CL: "Supported",
              CN: "Supported",
              CO: "Supported",
              CR: "Supported",
              CU: "Supported",
              CY: "Supported",
              CZ: "Supported",
              DE: "Supported",
              DK: "Supported",
              DO: "Supported",
              EC: "Supported",
              EE: "Supported",
              EG: "Supported",
              ES: "Supported",
              FI: "Supported",
              FO: "Supported",
              FR: "Supported",
              GA: "Supported",
              GB: "Supported",
              GD: "Supported",
              GE: "Supported",
              GG: "Supported",
              GI: "Supported",
              GL: "Supported",
              GM: "Supported",
              GR: "Supported",
              GT: "Supported",
              GY: "Supported",
              HK: "Supported",
              HN: "Supported",
              HR: "Supported",
              HT: "Supported",
              HU: "Supported",
              ID: "Supported",
              IE: "Supported",
              IM: "Supported",
              IS: "Supported",
              IT: "Supported",
              JE: "Supported",
              JM: "Supported",
              JP: "Supported",
              KR: "Supported",
              KZ: "Supported",
              LI: "Supported",
              LS: "Supported",
              LT: "Supported",
              LU: "Supported",
              LV: "Supported",
              MA: "Supported",
              MC: "Supported",
              MD: "Supported",
              ME: "Supported",
              MG: "Supported",
              MK: "Supported",
              MN: "Supported",
              MS: "Supported",
              MT: "Supported",
              MX: "Supported",
              MZ: "Supported",
              NA: "Supported",
              NE: "Supported",
              NG: "Supported",
              NI: "Supported",
              NL: "Supported",
              NO: "Supported",
              NZ: "Supported",
              PA: "Supported",
              PE: "Supported",
              PG: "Supported",
              PL: "Supported",
              PR: "Supported",
              PT: "Supported",
              PY: "Supported",
              RO: "Supported",
              RS: "Supported",
              RU: "Supported",
              SE: "Supported",
              SG: "Supported",
              SI: "Supported",
              SJ: "Supported",
              SK: "Supported",
              SM: "Supported",
              SR: "Supported",
              SV: "Supported",
              TN: "Supported",
              TR: "Supported",
              UA: "Supported",
              US: "Supported",
              UY: "Supported",
              VA: "Supported",
              VE: "Supported",
              VN: "Supported",
              ZA: "Supported",
              ZW: "Supported",
            },
          },
        ],
      },
      selector: "#map",
      map: "world",
    });
  }, []);
  return (
    <div className="countries-wrap">
      <Flex className="country-info">
        <div className="info">
          <div className="name">
            <strong>Countries</strong>,{" "}
            <small>an overview of all supported countries</small>
          </div>
        </div>
      </Flex>
      <div className="map-wrap">
        <div id="map" className="map"></div>
        The countries marked in green are already supported.
      </div>

      <List
        className="c-list"
        size="large"
        header={
          <Flex className="c-headers">
            <div className="title">
              <strong>Countries</strong>
            </div>
            <div>{countryList.length} countries are supported</div>
          </Flex>
        }
        dataSource={countryList}
        renderItem={(item) => (
          <List.Item>
            <Button
              type="link"
              className="name"
              onClick={() => {
                goDetail(item);
              }}
            >
              {item.name}
            </Button>
            <div>{item.countryCode}</div>
          </List.Item>
        )}
      />
    </div>
  );
}

export default Countries;
