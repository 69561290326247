import React, { useEffect, useState } from "react";
import Router from "./Router";
import info from "../package.json";
import { getAvailableCountries } from "@/api";
import AllStores from "@/stores/stores";

console.log(info.version);
if (process.env.REACT_APP_ENV === "production") {
  console.log = function () {};
}
console.log(process.env);
function App() {
  const [isInit, setIsInit] = useState(false);
  const { updateCountryList } = AllStores();
  useEffect(() => {
    getAvailableCountries().then((res) => {
      console.log("getAvailableCountries", res);
      res.forEach((item) => {
        item.value = item.countryCode;
        item.label = item.name + " (" + item.countryCode + ")";
      });
      updateCountryList(res);
      setIsInit(true);
    });
  }, [updateCountryList]);
  return isInit && <Router />;
}
export default App;
