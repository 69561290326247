import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "@/pages/Home/Home";
import Detail from "@/pages/Detail/Detail";
import NoMatch from "@/pages/404/404";
import Header from "@/pages/components/Header";
import Footer from "@/pages/components/Footer";
import About from "@/pages/About/About";
import Countries from "@/pages/Countries/Countries";
import Agreement from "../pages/Agreement/Agreement";
import Privacy from "../pages/Privacy/Privacy";

const BasicRoute = () => (
  <Router>
    <Header />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/countries" component={Countries} />
      <Route exact path="/about" component={About} />
      <Route exact path="/agreement" component={Agreement} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/detail/:id/:year" component={Detail} />
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
    <Footer />
  </Router>
);

export default BasicRoute;
