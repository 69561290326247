import { Button, Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import AllStores from "@/stores/stores";
import { getCountryName } from "@/utils";
import { getNext7Days } from "@/api";
import CTable from "@/pages/components/CTable";
import { CalendarOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom"

function WorldHolidays() {
  const history = useHistory();
  const { countryList } = AllStores();
  const [loading, setLoading] = useState(true);
  const [nextPubliclist, setNextPublicList] = useState([]);  

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Country",
      dataIndex: "countryCode",
      render: (_, record) => (
        <>
          <Button
            type="link"
            className="btn"
            onClick={() => {
                history.push(`/detail/${record.countryCode}/${new Date().getFullYear()}`);
            }}
          >
            {getCountryName(countryList, record.countryCode).name}
          </Button>
        </>
      ),
    },
  ];
  useEffect(() => {
    getNext7Days()
      .then((res) => {
        console.log("getNext7Days", res);
        setNextPublicList(res);
      })
      .catch((e) => {
        console.log("error", e);
      })
      .then(() => {
        console.log("finally");
        setLoading(false);
      });
  }, []);
  return (
    <Card loading={loading}>
      <Row>
        <Col>
          <h1 className="title">
            <CalendarOutlined /> Next Public Holidays Worldwide in the next 7
            days
          </h1>
          <CTable
            size="middle"
            columns={columns}
            dataSource={nextPubliclist}
            loading={loading}
            sticky={true}
            hidePagination={true}
            rowKey={(record) => record.countryCode + record.date + record.localName}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default WorldHolidays;
